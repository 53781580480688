<template>
  <div>
    <div class="pot">
      <input type="text" v-model="number" placeholder="请填写猪宅id" />
      <!-- <div @click="dianjiList" class="button">
        打印
      </div> -->
      <button type="primary" plain @click="dianjiList">打印</button>
    </div>
    <div
      class="bg"
      v-show="isShow"
      v-for="(item, index) in pigList"
      :key="index"
    >
      <div :id="'tableId' + index">
        <div class="td caption cl">
          领养人：
        </div>
        <div class="caption tr">
          {{ item.name }}
        </div>
        <div class="caption cl">
          猪仔编号:
        </div>
        <div class="caption tr num">
          {{ item.pig_num }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getLodop from '../api/lofop'
import { dump_pig } from '../api/index'
import { MessageBox } from 'element-ui'
export default {
  data() {
    return {
      name: '',
      pig_num: '',
      image: '',
      isShow: false,
      pigList: [],
      number: ''
    }
  },
  mounted() {},
  methods: {
    async dump_pigData() {
      let res = await dump_pig({
        pid: this.number
      })
      console.log(res, '猪仔信息')
      // console.log(res.data.code, '猪仔信息')
      if (res.data.code != 200) return
      console.log(res.data.data.list[0], '猪仔信息')
      // this.pig_num = res.data.data.list[0].pig_num
      // this.name = res.data.data.list[0].name
      this.pigList = res.data.data.list
      this.image = res.data.data.image
    },
    async dianjiList() {
      // this.isShow = true
      if (this.number) {
        await this.dump_pigData()
        console.log(this.pigList.length, '长度')
        await this.printReport()
      } else {
        MessageBox({
          title: '温馨提示',
          type: 'warning',
          showCancelButton: false,
          message: '请填写正确的猪宅id',
          callback: (res) => {
            if (res === 'confirm') {
            }
          }
        })
      }

      // setTimeout(() => {

      // }, 2000)
    },
    // 打印报表
    printReport() {
      this.isShow = true
      var LODOP = getLodop()

      if (LODOP) {
        var strBodyStyle = '<style>'
        strBodyStyle +=
          'div { font-size:70px; font-weight: 800;font-family: Source Han Sans CN;}'
        strBodyStyle += '.caption { margin-bottom: 60px;margin-left: 200px; }'
        strBodyStyle +=
          '.tr { margin-bottom: 90px; color: #0D7100;font-size:90px; }'
        // strBodyStyle += '.bg { background-image:url("../assets/beijin.png"); }'
        strBodyStyle += '.cl { color: #8A2A00; }'
        strBodyStyle +=
          '.num { margin-bottom: 120px; color: #0D7100;font-size:90px; }'
        strBodyStyle +=
          // '.td {margin-bottom: 60px;padding-top: 120px;box-sizing: border-box;color: #8A2A00;}'
          '.td {margin-bottom: 60px;padding-top: 120px;box-sizing: border-box;color: #8A2A00;}'
        strBodyStyle += '</style>' //设置打印样式

        console.log(this.image, '图片地址')
        LODOP.PRINT_INIT('') //首先一个初始化语句
        LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' src=${this.image}>`) //背景图 //设置Lodop背景图
        // LODOP.ADD_PRINT_SETUP_BKIMG(`<img border='0' :src="image">`) //背景图 //设置Lodop背景图
        // LODOP.ADD_PRINT_SETUP_BKIMG(
        //   "<img  border='0' src='http://video.hxnc.cc/hxnc/file/beijin.png'>"
        // ) //背景图 //设置Lodop背景图
        // LODOP.ADD_PRINT_SETUP_BKIMG('../assets/beijin.png') //背景图 //设置Lodop背景图
        // http://video.hxnc.chxnc/file/beijin.png
        // LODOP.SET_SHOW_MODE('BKIMG_LEFT', 1)
        // LODOP.SET_SHOW_MODE('BKIMG_TOP', 1)
        // LODOP.SET_SHOW_MODE('BKIMG_WIDTH', 1)
        // LODOP.PRINT_DESIGN()

        LODOP.SET_SHOW_MODE('LANDSCAPE_DEFROTATED', 1) //横向打印的预览默认旋转90度（正向显示）
        // LODOP.SET_PRINT_PAGESIZE(2, 350800, 248000, '') //设置纸张大小
        // LODOP.SET_PRINT_PAGESIZE(2, 0, 0, '') //设置纸张大小
        LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4') //设置纸张大小
        LODOP.SET_PREVIEW_WINDOW(2, 0, 0, 1000, 1000, '') //设置预览窗口模式和大小
        // LODOP.ADD_PRINT_TEXT(0, 0, 179, 33, '领养人：`${ name }`\n猪仔编号') //ADD语句添加一个文本打印项，文本里换行了。
        for (var i = 0; i < this.pigList.length; i++) {
          // LODOP.Nevpage
          var strFormHtml =
            strBodyStyle +
            '<body>' +
            document.getElementById('tableId' + i).innerHTML +
            '</body>' //获取打印内容
          LODOP.NewPage()
          LODOP.ADD_PRINT_HTM('1%', '1%', '98%', '98%', strFormHtml) //设置打印内容
          // LODOP.ADD_PRINT_TEXT(75, 6, 100, 20, strFormHtml[i])
        }
        // LODOP.ADD_PRINT_TEXT(0, 0, 179, 33, strFormHtml) //ADD语句添加一个文本打印项，文本里换行了。
        // LODOP.SET_PRINT_STYLEA(0, 'LineSpacing', '90') // SET语句设置打印项样式，设置行间距。
        LODOP.SET_SHOW_MODE('BKIMG_IN_PREVIEW', 1) //预览包含背景图
        LODOP.SET_SHOW_MODE('BKIMG_PRINT', 1) //打印包含背景图
        LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '288mm')
        // LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '210mm')
        // LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '350.8mm')
        // LODOP.SET_PRINT_STYLEA(0, 'AngleOfPageInside', 270)
        LODOP.PREVIEW() //最后一个打印(或预览、维护、设计)语句
        // LODOP.PRINT()
        this.isShow = false
        // LODOP.PRINT_INIT('') //初始化
        // LODOP.ADD_PRINT_SETUP_BKIMG(
        //   "<img border='0' src='http://s1.sinaimg.cn/middle/721e77e5t99431b026bd0&690'>"
        // ) //背景图
        // LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4') //设置纵向 设置纸张大小
        // // LODOP.ADD_PRINT_HTM('1%', '1%', '98%', '98%', '打摘') //设置打印内容
        // LODOP.SET_PREVIEW_WINDOW(2, 0, 0, 800, 600, '') //设置预览窗口模式和大小
        // // LODOP.ADD_PRINT_SETUP_BKIMG(strHtmFile)
        // LODOP.ADD_PRINT_TEXT(0, 0, 100, 20, '文本内容一') //然后多个ADD语句及SET语句
        // LODOP.SET_SHOW_MODE('BKIMG_WIDTH', 1)
        // LODOP.PREVIEW()
      }

      // LODOP.PRINT_INIT('') //初始化
      // LODOP.ADD_PRINT_SETUP_BKIMG(
      //   "<img border='0' src='http://s1.sinaimg.cn/middle/721e77e5t99431b026bd0&690'>"
      // ) //背景图
      // // LODOP.ADD_PRINT_TEXT(0, 0, 100, 20, '文本内容一') //文本项
      // LODOP.ADD_PRINT_HTM('1%', '1%', '98%', '98%', '打摘') //设置打印内容
      // LODOP.SET_PREVIEW_WINDOW(2, 0, 0, 800, 600, '') //设置预览窗口模式和大小
      // LODOP.SET_SHOW_MODE('BKIMG_IN_PREVIEW', 1) //预览包含背景图
      // LODOP.PREVIEW()
      // LODOP.PRINT_INIT('') //初始化
      // LODOP.ADD_PRINT_SETUP_BKIMG(
      //   "<img border='0' src='http://s1.sinaimg.cn/middle/721e77e5t99431b026bd0&690'>"
      // ) //背景图
      // LODOP.ADD_PRINT_TEXT(0, 0, 100, 20, '文本内容一') //文本项
      // LODOP.SET_SHOW_MODE('BKIMG_PRINT', 1) //打印包含背景图
      // LODOP.SET_SAVE_MODE('SAVEAS_IMGFILE_EXENAME', '.emf')
      // LODOP.SAVE_TO_FILE('新的矢量图片文件.emf')
    }
  }
}
</script>
<style lang="scss" scoped>
.pot {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  input {
    width: 400px;
    height: 50px;
    // margin-right: 3px;
    border: 1px solid #ccc;
    // text-indent: 2px;
    padding: 0 8px;
    box-sizing: border-box;
    color: #666;
    line-height: 50px;
  }
  button {
    width: 80px;
    // height: 34px;
    height: 50px;
    // line-height: 30px;
    // text-align: center;
    border: none;
    font-size: 12px;
  }
}
input::placeholder {
  line-height: 36px;
  color: #999;
  font-size: 12px;
  text-indent: 4px;
}
// 消除输入框加减按钮
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>
